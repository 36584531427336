import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {PortfolioTypeHttpService} from '@api-client/https/portfolio-type-http.service';
import {PreventAlbedaGuard} from '@core/guards/prevent-albeda.guard';
import {UserStateGuard} from '@core/guards/user-state.guard';
import {AccountHttpService} from '@core/https/account-http.service';
import {AppVersionHttpService} from '@core/https/app-version-http.service';
import {AppVersionInterceptor} from '@core/interceptors/app-version.interceptor';
import {PortfolioTypeConfigResolver} from '@core/resolvers/portfolio-type-config.resolver';
import {AppVersionService} from '@core/services/app-version.service';
import {PortfolioTypeConfigEffects} from '@core/store/portfolio-type-config/portfolio-type-config.effects';
import {environment} from '@env/environment';
import {EntityDataModule} from '@ngrx/data';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {TranslationCustomLoader} from '../../assets/i18n/language';
import {entityConfig} from './store/data/entity-metadata';
import {ApplicationInsightsErrorHandler} from './error-handlers/application-insights.error-handler';
import {AuthGuard} from './guards/auth.guard';
import {DevOnlyGuard} from './guards/dev-only.guard';
import {FormSubmissionGuard} from './guards/form-submission.guard';
import {LocalizationGuard} from './guards/localization.guard';
import {PreventBrowserBackGuard} from './guards/prevent-browser-back.guard';
import {UserRoleGuard} from './guards/user-role.guard';
import {ViewModeGuard} from './guards/view-mode.guard';
import {AdminCategoryHttpService} from './https/admin-category-http.service';
import {BannerNotificationHttpService} from './https/banner-notification-http.service';
import {CompanyHttpService} from './https/company-http.service';
import {DashboardHttpService} from './https/dashboard-http.service';
import {EpaCreationHttpService} from './https/epa-creation-http.service';
import {EpaLibraryHttpService} from './https/epa-library-http.service';
import {LanguageHttpService} from './https/language-http.service';
import {LocalizationHttpService} from './https/localization-http.service';
import {NotificationHttpService} from './https/notification-http.service';
import {ProfileHttpService} from './https/profile-http.service';
import {ApiInterceptor} from './interceptors/api.interceptor';
import {CacheInterceptor} from './interceptors/cache.interceptor';
import {ErrorHandlerInterceptor} from './interceptors/error-handler.interceptor';
import {RefreshTokenInterceptor} from './interceptors/refresh-token.interceptor';
import {UserInfoResolver} from './resolvers/user-info.resolver';
import {UserProfileResolver} from './resolvers/user-profile.resolver';
import {ApplicationInsightsService} from './services/application-insights.service';
import {AuthService} from './services/auth.service';
import {BannerNotificationService} from './services/banner-notification.service';
import {CacheKeyService} from './services/cache-key.service';
import {CacheMonitorService} from './services/cache-monitor.service';
import {CacheService} from './services/cache.service';
import {DashboardTabService} from './services/dashboard-tab.service';
import {DownloadService} from './services/download.service';
import {FiltersService} from './services/filters.service';
import {FormAccessService} from './services/form-access.service';
import {FormRedirectionService} from './services/form-redirection.service';
import {FormService} from './services/form.service';
import {HubspotService} from './services/hubspot.service';
import {IdleService} from './services/idle.service';
import {IndexedDbService} from './services/indexed-db.service';
import {IopAccessService} from './services/iop-access.service';
import {LocalizationService} from './services/localization.service';
import {LocationService} from './services/location.service';
import {NotificationService} from './services/notification.service';
import {PaginatorTranslationService} from './services/paginator-translation.service';
import {RouterService} from './services/router.service';
import {TodoService} from './services/todo.service';
import {TokenExpiryService} from './services/token-expiry.service';
import {TranslateMonthService} from './services/translate-month.service';
import {TranslationService} from './services/translation.service';
import {UrlService} from './services/url.service';
import {metaReducers, reducers} from './store/app.reducers';
import {AuthEffects} from './store/auth/auth.effects';
import {DashboardEffects} from './store/dashboard/dashboard.effects';
import {EpaLibraryEffects} from './store/epa-library/epa-library.effects';
import {LanguageEffects} from './store/langauge/language.effects';
import {UserInfoEffects} from './store/user-info/user-info.effects';
import {ViewStudentPortfolioEffects} from './store/view-student-portfolio/view-student-portfolio.effects';
import {LoggingService} from './services/logging.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: false,
};

const guards = [
  DevOnlyGuard,
  LocalizationGuard,
  UserRoleGuard,
  AuthGuard,
  FormSubmissionGuard,
  ViewModeGuard,
  UserStateGuard,
  PreventBrowserBackGuard,
  PreventAlbedaGuard,
];

const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefreshTokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorHandlerInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AppVersionInterceptor,
    multi: true,
  },
];

const services = [
  PaginatorTranslationService,
  NotificationService,
  LocalizationService,
  FormRedirectionService,
  DownloadService,
  AuthService,
  TranslateMonthService,
  DashboardTabService,
  FormAccessService,
  IopAccessService,
  LocationService,
  RouterService,
  ApplicationInsightsService,
  CacheService,
  CacheMonitorService,
  CacheKeyService,
  IdleService,
  TokenExpiryService,
  UrlService,
  IndexedDbService,
  FormService,
  AppVersionService,
  BannerNotificationService,
  HubspotService,
  TranslationService,
  TodoService,
  FiltersService,
  LoggingService,
];

const https = [
  LanguageHttpService,
  ProfileHttpService,
  NotificationHttpService,
  LocalizationHttpService,
  PortfolioTypeHttpService,
  AccountHttpService,
  AppVersionHttpService,
  BannerNotificationHttpService,
  CompanyHttpService,
  EpaCreationHttpService,
  AdminCategoryHttpService,
  DashboardHttpService,
  EpaLibraryHttpService,
];

const effects = [
  UserInfoEffects,
  LanguageEffects,
  PortfolioTypeConfigEffects,
  ViewStudentPortfolioEffects,
  AuthEffects,
  DashboardEffects,
  EpaLibraryEffects,
];

const resolvers = [
  UserProfileResolver,
  UserInfoResolver,
  PortfolioTypeConfigResolver,
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationCustomLoader,
      },
    }),
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot(effects),
    EntityDataModule.forRoot(entityConfig),
    MatSnackBarModule,
  ],
  providers: [
    ...guards,
    ...interceptors,
    ...https,
    ...services,
    ...resolvers,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationInsightsErrorHandler,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded.');
    }
  }
}
